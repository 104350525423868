import React, { useState } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import { graphql, useStaticQuery } from "gatsby";

const trimImages = (imageSet) => {
  return imageSet.map(({ node }) => {
    return {
      src: node.childImageSharp.fluid.src,
      // thumbnail: node.childImageSharp.resize.src,
      width: 4,
      height: 3,
    };
  });
};

const extractLinks = (images) => {
  return images.map((node) => {
    return node.src;
  });
};

const GalleryComponent = () => {
  const data = useStaticQuery(query);

  const images = trimImages(data.images.edges);
  const imageLinks = extractLinks(images);

  const [isLigthboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <>
      <div className={"row"}>
        <div className={"w-100"}>
          {isLigthboxOpen && (
            <>
              <Lightbox
                mainSrc={imageLinks[photoIndex]}
                nextSrc={imageLinks[(photoIndex + 1) % imageLinks.length]}
                prevSrc={
                  imageLinks[
                    (photoIndex + imageLinks.length - 1) % imageLinks.length
                  ]
                }
                onCloseRequest={() => {
                  setIsLightboxOpen(false);
                }}
                onMovePrevRequest={() => {
                  setPhotoIndex(
                    (photoIndex + imageLinks.length - 1) % imageLinks.length
                  );
                }}
                onMoveNextRequest={() => {
                  setPhotoIndex((photoIndex + 1) % imageLinks.length);
                }}
              />
            </>
          )}

          <div className="my-5">
            <Gallery
              margin={8}
              photos={images}
              onClick={(e, { photo, index }) => {
                setPhotoIndex(index);
                setIsLightboxOpen(true);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryComponent;

const query = graphql`
  {
    images: allFile(filter: { sourceInstanceName: { eq: "images-gallery" } }) {
      edges {
        node {
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1500) {
              src
            }
          }
        }
      }
    }
  }
`;
