import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Divider from "../common/divider";
import ChevronDown from "../common/Chevron";

const Wrapper = styled.div`
  position: relative;
`;

const ColumnWrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  height: calc(100vh - 60px);

  .bgImage {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: calc(100vh - 60px);
    object-fit: cover;
  }

  @media screen and (max-width: 1200px) {
    height: 500px;
    .bgImage {
      height: 500px;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);

  @media screen and (max-width: 1200px) {
    height: 500px;
  }
`;

const Content = styled.div`
  position: absolute;
  z-index: 10;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 45px;

    @media screen and (max-width: 576px) {
      font-size: 34px;
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(query);

  const autoImage = data.autoImage.childImageSharp.fluid.src;
  const homeImage = data.homeImage.childImageSharp.fluid.src;

  return (
    <>
      <Wrapper>
        <Row className="mx-0 px-0">
          <Col xl={6} className="mx-0 px-0">
            <ColumnWrapper>
              <img src={homeImage} className="bgImage" alt={'Битово-ключарски услуги'} />
              <Background className="content">
                <Content>
                  <div className="text-center px-3">
                    <h1 className="text-gold">
                      Битово-ключарски
                      <br />
                      услуги
                    </h1>
                    <Divider addClassName="gold" />

                    <p>
                      Аварийно отключване 24/7, изработка на битови ключове,
                      посещение на адрес, ремонт, монтаж и продажба на брави и
                      патрони, оглед и консултация
                    </p>

                    <Link
                      to="/bitovo-klucharski-uslugi"
                      className="btn btn-primary mt-3"
                    >
                      Виж повече
                    </Link>
                  </div>
                </Content>
              </Background>
            </ColumnWrapper>
          </Col>

          <Col xl={6} className="mx-0 px-0">
            <ColumnWrapper>
              <img src={autoImage} className="bgImage" alt={'Автоключарски услуги'} />
              <Background className="content">
                <Content>
                  <div className="text-center px-3">
                    <h1 className="text-white">
                      Автоключарски
                      <br />
                      услуги
                    </h1>
                    <Divider addClassName="white" />

                    <p>
                      Отключване от автоключар 24/7, изработка на дубликат,
                      монтаж и демонтаж на автоключалки, подмяна на кутийки,
                      смяна на батерии, оглед и консултация
                    </p>

                    <Link
                      to="/avtoklucharski-uslugi"
                      className="btn btn-secondary mt-3"
                    >
                      Научи повече
                    </Link>
                  </div>
                </Content>
              </Background>
            </ColumnWrapper>
          </Col>
        </Row>
        <ChevronDown />
      </Wrapper>
    </>
  );
};

const query = graphql`
  query IndexHero {
    autoImage: file(relativePath: { eq: "index/hero-right.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          src
        }
      }
    }
    homeImage: file(relativePath: { eq: "index/hero-left.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          src
        }
      }
    }
  }
`;

export default Hero;
