import { graphql } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import Divider from "../components/common/divider";
import Map from "../components/common/map";
import WayOfWork from "../components/common/WayOfWork";
import Contact from "../components/common/Contact";
import Hero from "../components/index/hero";
import Layout from "../components/site-wide/layout/layout";
import constants from "../utils/constants";
import FullWidthCta from "../components/common/FullWidthCta";
import GalleryComponent from "../components/about/gallery";

const IndexPage = ({ data }) => (
  <Layout title={constants.INDEX.META.TITLE}>
    <Hero />

    <section id="first-section">
      <Container className="text-center py-5">
        <h2 className="mt-3">Денонощни услуги за гр. София</h2>
        <Divider addClassName="gold" />
        <h5>
          Екип от професионалисти. Реагираме до 30 мин. Най-съвременна техника.
        </h5>
      </Container>

      <FullWidthCta bgImage={data.paralaxImage.childImageSharp.fluid.src} />
    </section>

    <WayOfWork />

    <section id="list-of-businesses">
      <GalleryComponent />
    </section>

    <section id="contact">
      <Container className="py-5">
        <div className="text-center">
          <h2 className="mt-3">Свържи се с нас</h2>
          <Divider addClassName="mb-4 gold" />
        </div>

        <Contact />
      </Container>
    </section>
    <Map />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query IndexPage {
      paralaxImage: file(relativePath: {eq: "index/paralax.jpg"}) {
        childImageSharp {
          fluid(jpegQuality: 100, maxWidth: 2000) {
            src
          }
        }
      }
    }
`;
